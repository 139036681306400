import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EquipamentosContext } from 'contexts/EquipamentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import EquipamentoModal from 'components/Modals/EquipamentoModal';
import FullEquipamentoModal from 'components/Modals/FullEquipamentoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';

const Equipamentos = () => {
  const rotina = 'Equipamento';
  const titulo = 'Equipamentos';
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { equipamentos, getEquipamentos, getLoading } =
    useContext(EquipamentosContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { deleteGrid } = useContext(GridContext);
  const { drops } = useContext(DropsContext);
  const { control, getValues, reset } = useForm({
    defaultValues: equipamentos?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getEquipamentos({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () =>
        openDialog(
          <FullEquipamentoModal callback={loadGrid} />,
          'Cadastrar Equipamento'
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row }) =>
        openDialog(
          <EquipamentoModal callback={loadGrid} equipamento={row} />,
          'Editar Equipamento'
        ),
    },
    {
      name: 'Deletar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                id: row?.id,
                rotina,
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
    {
      name: 'Histórico de manutenção',
      icon: 'description',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'HME0000001', equipamento_id: row?.id },
        }),
    },
    {
      name: 'Relacionados',
      icon: 'splitscreen',
      menu: true,
      action: ({ row }) =>
        navigate(`/app/Equipamentos/Relacionados/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={equipamentos?.colunas} />
      <Card>
        <Grid
          grid={equipamentos}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={equipamentos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Equipamentos;
