import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toastWarning } from 'utils/toast';
import {
  checkSaldoDocumento,
  checkOrdemServico,
  encryptURL,
} from 'utils/functions';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { VendaContext } from 'contexts/VendaContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import JustificativaModal from 'components/Modals/JustificativaModal';
import AlteraStatusModal from 'components/Modals/AlteraStatusModal';
import GeraçãoModal from 'components/Modals/GeraçãoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Ordens = () => {
  const navigate = useNavigate();
  const rotina = 'OrdensDeServico';
  const titulo = 'Ordens de Serviço';
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { contas } = useContext(VendaContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const {
    ordens,
    getOrdens,
    getLoading,
    postDocumento,
    postFatura,
    exportOrdens,
  } = useContext(OrdensContext);
  const { control, getValues, reset } = useForm({
    defaultValues: ordens?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getOrdens({ ...props, rotina, filter: getValues() });
  };

  const changeStatus = ({ documento_id, status_id, motivo }) => {
    const data = { Registros: [{ documento_id, status_id, motivo }] };
    postDocumento({ data, cb: () => loadGrid() });
  };

  const onSubmitFatura = (values) => {
    const data = { id: values?.id };
    postFatura({ data, cb: loadGrid });
  };

  const options = [
    {
      name: 'Nova Ordem de Serviço',
      icon: 'add',
      action: () => {
        if (user?.atividade_filial === 877) {
          navigate('/app/Ordem/Equipamento');
        } else {
          navigate('/app/Ordem/Servico');
        }
      },
    },
    {
      name: 'Ordens',
      icon: 'print',
      action: (selected) => {
        if (Boolean(selected?.length)) {
          const data = selected?.map((s) => s?.id);
          exportOrdens({ data, cb: loadGrid });
        } else {
          return toastWarning('Selecione ao menos um item');
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Em execução',
      icon: 'schedule',
      color: 'primary.main',
      show: ({ row }) => Boolean(row?.executantes),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Ordem/${row?.id}`),
    },
    {
      name: 'Agendamento',
      icon: 'calendar_month',
      menu: true,
      show: () =>
        user?.nivel === 'LIDER' ||
        user?.nivel === 'DIRETORIA' ||
        user?.nivel === 'GERENCIA' ||
        user?.nivel === 'SUPERVISÃO',
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 22 }),
    },
    {
      name: 'Apontamento de Horas',
      icon: 'schedule',
      menu: true,
      action: ({ row }) =>
        navigate('/app/Apontamento', { state: { documento: row } }),
    },
    {
      name: 'Direcionamento Técnico',
      icon: 'build',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        row?.status_id !== 6 &&
        row?.status_id !== 19 &&
        row?.status_id !== 20,
      action: ({ row }) =>
        navigate('/app/Tecnico', { state: { documento: row } }),
    },
    {
      name: 'Liberar Ordem de Serviço',
      icon: 'verified',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        (row?.status_id === 21 || row?.status_id === 22),
      action: async ({ row }) => {
        const check = await checkOrdemServico(row?.id);
        if (check || row?.tipo === 'GARANTIA') {
          changeStatus({ documento_id: row?.id, status_id: 18 });
        }
      },
    },
    {
      name: 'Orçamento',
      icon: 'request_quote',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'OSORC00001', documento_id: row?.id },
          documento: row,
          cb: () => loadGrid(),
        }),
    },
    {
      name: 'Cupom',
      icon: 'request_quote',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'OSCUPOM001', documento_id: row?.id },
          documento: row,
          cb: () => loadGrid(),
        }),
    },
    {
      name: 'Encaminhar Orçamento',
      icon: 'request_quote',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'TÉCNICO' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        (row?.status_id === 18 || row?.status_id === 24),
      action: ({ row }) => {
        if (Boolean(row?.tipo !== 'GARANTIA') && !Boolean(row?.preco)) {
          toastWarning('Valor do documento não informado');
        } else if (Boolean(row?.equipamento_id) && !Boolean(row?.localizacao)) {
          toastWarning('Localização do equipamento não informada');
        } else {
          changeStatus({ documento_id: row?.id, status_id: 25 });
        }
      },
    },
    {
      name: 'Registrar Aprovação do Cliente',
      icon: 'thumb_up',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'ATENDENTE' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        row?.status_id === 17,
      action: async ({ row }) => {
        const data = await checkSaldoDocumento({
          documento_id: row?.id,
          user,
          dropNatureza: drops?.NaturezaOperacao,
        });
        postDocumento({ data, cb: () => loadGrid() });
      },
    },
    {
      name: 'Registrar Reprovação do Cliente',
      icon: 'thumb_down',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'ATENDENTE' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        row?.status_id === 17,
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 19 }),
    },
    {
      name: 'Concluir Serviço',
      icon: 'check',
      menu: true,
      show: ({ row }) =>
        (user?.nivel === 'TÉCNICO' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO') &&
        row?.status_id === 18,
      action: ({ row }) => {
        if (Boolean(row?.equipamento_id) && Boolean(row?.locais < 2)) {
          toastWarning('Localização do equipamento não informada');
        } else if (Boolean(row?.executantes)) {
          toastWarning('Finalize todos os apontamentos da ordem');
        } else if (Boolean(row?.vldocto <= 0)) {
          toastWarning('Documento sem valor');
        } else {
          changeStatus({ documento_id: row?.id, status_id: 20 });
        }
      },
    },
    {
      name: 'Devolver Equipamento',
      icon: 'redo',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        row?.status_id !== 2 &&
        row?.status_id !== 6 &&
        row?.status_id !== 20 &&
        row?.status_id !== 26 &&
        row?.status_id !== 27 &&
        (user?.nivel === 'TÉCNICO' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO'),
      action: ({ row }) => {
        if (Boolean(row?.equipamento_id) && Boolean(row?.locais < 2)) {
          toastWarning('Localização do equipamento não informada');
        } else if (Boolean(row?.executantes)) {
          toastWarning('Finalize todos os apontamentos da ordem');
        } else {
          changeStatus({ documento_id: row?.id, status_id: 26 });
        }
      },
    },
    {
      name: 'Descartar Equipamento',
      icon: 'delete',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        row?.status_id !== 2 &&
        row?.status_id !== 6 &&
        row?.status_id !== 20 &&
        row?.status_id !== 27,
      action: ({ row }) =>
        changeStatus({ documento_id: row?.id, status_id: 27 }),
    },
    {
      name: 'Registrar Entrega do Equipamento',
      icon: 'check',
      menu: true,
      show: ({ row }) =>
        Boolean(row?.equipamento_id) &&
        (row?.status_id === 26 ||
          row?.status_id === 6 ||
          row?.status_id === 19 ||
          (user?.nivel === 'ATENDENTE' && row?.status_id === 21)),
      action: ({ row }) =>
        changeStatus({
          documento_id: row?.id,
          status_id: 6,
          motivo: 'Equipamento Entregue',
        }),
    },
    {
      name: 'Cancelar Serviço',
      icon: 'cancel',
      menu: true,
      show: () =>
        user?.nivel === 'LIDER' ||
        user?.nivel === 'DIRETORIA' ||
        user?.nivel === 'GERENCIA' ||
        user?.nivel === 'SUPERVISÃO' ||
        user?.nivel === 'ATENDENTE',
      action: ({ row }) =>
        openDialog(
          <JustificativaModal
            onSubmit={({ motivo }) => {
              changeStatus({ documento_id: row?.id, status_id: 2, motivo });
              closeDialog();
            }}
          />,
          'Cancelar Serviço'
        ),
    },
    {
      name: 'Resumo da Ordem de Serviço',
      icon: 'description',
      menu: true,
      show: () =>
        user?.nivel === 'LIDER' ||
        user?.nivel === 'DIRETORIA' ||
        user?.nivel === 'GERENCIA' ||
        user?.nivel === 'SUPERVISÃO',
      action: ({ row }) =>
        navigate('/app/Ordem/Resumo', { state: { documento: row } }),
    },
    {
      name: 'Alterar Status',
      icon: 'undo',
      menu: true,
      show: () =>
        user?.nivel === 'LIDER' ||
        user?.nivel === 'DIRETORIA' ||
        user?.nivel === 'GERENCIA' ||
        user?.nivel === 'SUPERVISÃO',
      action: ({ row }) =>
        openModal(
          <AlteraStatusModal
            user={user}
            documento_id={row?.id}
            onSubmit={changeStatus}
          />
        ),
    },
    // {
    //   name: 'Gerar Documento',
    //   icon: 'article',
    //   menu: true,
    //   show: ({ row }) =>
    //     row?.status_id === 20 &&
    //     (user?.nivel === 'ATENDENTE' ||
    //       user?.nivel === 'LIDER' ||
    //       user?.nivel === 'DIRETORIA' ||
    //       user?.nivel === 'GERENCIA' ||
    //       user?.nivel === 'SUPERVISÃO'),
    //   action: ({ row }) =>
    //     Boolean(contas?.cxconta_id)
    //       ? openModal(
    //           <GeraçãoModal
    //             onSubmit={(type) => {
    //               if (Boolean(type)) {
    //                 window.open(
    //                   `https://comercial.eprom2.com.br/app/Caixa/${type}/${row?.id}`
    //                 );
    //               }
    //             }}
    //           />
    //         )
    //       : onSubmitFatura(row),
    // },
    {
      name: 'Faturar',
      icon: 'attach_money',
      menu: true,
      show: ({ row }) =>
        row?.status_id === 20 &&
        (user?.nivel === 'ATENDENTE' ||
          user?.nivel === 'LIDER' ||
          user?.nivel === 'DIRETORIA' ||
          user?.nivel === 'GERENCIA' ||
          user?.nivel === 'SUPERVISÃO'),
      action: ({ id }) => {
        const hash = encryptURL({ id });
        if (Boolean(contas?.cxconta_id) && !Boolean(contas?.seqcaixa)) {
          return toastWarning('Você deve abrir o caixa para continuar');
        }
        navigate(`/app/Ordem/Faturamento/${hash}`);
      },
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={ordens?.colunas} />
      <Card>
        <Grid
          grid={ordens}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={ordens} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Ordens;
